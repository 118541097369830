import React, { useEffect, useState } from "react";

const PuntoFlotante = ({
  color,
  size,
  animationDuration = 0,
  opacity = 1,
  hasParticles = false,
  isAbsolute = false, // Nueva propiedad para controlar el posicionamiento
}) => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    if (!hasParticles) return; // No generar partículas si hasParticles es false

    const interval = setInterval(() => {
      const newParticle = {
        id: Math.random(),
        x: Math.random() * size - size / 2, // Posición aleatoria
        y: Math.random() * size - size / 2, // Posición aleatoria
        size: Math.random() * (size / 6) + size / 10, // Tamaño de la partícula
        duration: Math.random() * 3 + 1, // Duración de la animación
      };
      setParticles((prev) => [...prev, newParticle]);

      // Eliminar partículas antiguas después de que desaparezcan
      setTimeout(() => {
        setParticles((prev) => prev.filter((p) => p.id !== newParticle.id));
      }, newParticle.duration * 1000);
    }, 2000); // Intervalo de 2 segundos para generar partículas

    return () => clearInterval(interval);
  }, [size, hasParticles]);

  return (
    <div
      style={{
        position: isAbsolute ? "absolute" : "relative", // Cambia entre absoluto o relativo
        top: isAbsolute ? "30%" : "auto",
        left: isAbsolute ? "50%" : "auto",
        width: size,
        height: size,
        transform: isAbsolute ? "translate(-50%, -50%)" : "none", // Solo aplica centrar si es absoluto
      }}
    >
      {/* Círculo principal */}
      <svg
        width={size}
        height={size}
        style={{
          animation: animationDuration
            ? `floating ${animationDuration}s ease-in-out infinite`
            : "none",
        }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 5} // Margen para mantener un borde suave
          fill={color}
          opacity={opacity}
          style={{
            filter: "blur(3px)",
          }}
        />
      </svg>

      {/* Partículas esporádicas */}
      {hasParticles &&
        particles.map((particle) => (
          <div
            key={particle.id}
            style={{
              position: "absolute",
              top: `calc(50% + ${particle.y}px)`,
              left: `calc(50% + ${particle.x}px)`,
              width: particle.size,
              height: particle.size,
              backgroundColor: color,
              borderRadius: "50%",
              opacity: 0.8,
              filter: "blur(2px)",
              animation:
                animationDuration > 0
                  ? `particle-animation ${particle.duration}s ease-out`
                  : "none",
            }}
          />
        ))}

      {/* Estilos y animaciones */}
      <style>
        {`
          @keyframes floating {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }

          @keyframes particle-animation {
            0% {
              transform: scale(1) translate(0, 0);
              opacity: 1;
            }
            100% {
              transform: scale(0.5) translate(${Math.random() * 50 - 25}px, ${
          Math.random() * 50 - 25
        }px);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PuntoFlotante;
